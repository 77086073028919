import { useState, useCallback } from 'react';
import { MobileTab, useResponsive } from '@op-bridge/bridge-core';

import { Wrapper, Title, SubTitle, PageWrapper } from './withdraw';
import { useGetChainProviders } from '../hooks';
import Deposit from '../components/Deposit';
import { Container } from '../components/style';
import { ReadyToClaim } from '../components/modal/ReadyToClaim';
import { ThirdPartyBridgeTabs } from '../components/ThirdPartyBridgeTabs';

export default function Index() {
  const { l1Chain, l2Chain } = useGetChainProviders();
  const [tokenType, setTokenType] = useState('BNB');
  const { isMobile } = useResponsive();

  const handleTokenType = useCallback((type: string) => {
    setTokenType(type);
  }, []);

  // const isMultiThirdParty = type === 'thirdparty' ? true : false;
  const isMultiThirdParty = false;

  return (
    <PageWrapper>
      <ReadyToClaim />
      {isMobile && <MobileTab />}
      <Container>
        <Wrapper>
          <Title>{isMultiThirdParty ? 'Third Party Bridge' : 'Deposit'}</Title>
          {!isMobile && (
            <SubTitle>
              {isMultiThirdParty
                ? 'More tokens and faster in the third-party bridge.'
                : `Transfer ${tokenType} from ${
                    l1Chain && l1Chain?.length > 0 && l1Chain[0].name
                  } to${' '}
            ${l2Chain && l2Chain?.length > 0 && l2Chain[0].name}.`}
            </SubTitle>
          )}
          {isMultiThirdParty ? (
            <ThirdPartyBridgeTabs />
          ) : (
            <Deposit handleTypeChange={handleTokenType} tokenType={tokenType} />
          )}
        </Wrapper>
      </Container>
    </PageWrapper>
  );
}

// export async function getServerSideProps(context: any) {
//   const query = context?.query;

//   return { props: { type: query?.type || null } };
// }
